<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ProfileData from "./profileData";
import ChangePassword from "./change-password"
import UpdateAvatar from "./update-avatar"

import {
  layoutComputed,
  authComputed,
  usersMethods,
  usersComputed,
} from "@/state/helpers";
/**
 * Profile component
 */
export default {
  page: {
    title: "Profile",
    meta: [{ name: "Profile" }],
  },
  components: {
    Layout,
    PageHeader,
    ProfileData,
    ChangePassword,
    UpdateAvatar,
  },
  mounted() {
    this.retrieveUser();
  },
  data() {
    return {
      title: "titles.profile.text",
      busy: false,
      userProfile: null,
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...usersComputed,

    items() {
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.profile.text"),
          active: true,
        },
      ];
    },

    user() {
      return this.loggedIn.user;
    },
  },
  methods: {
    ...usersMethods,

    retrieveUser() {
      if (this.loggedIn.user) {
        this.userProfile = this.loggedIn.user;
      }
    },

    async changePass(passwords){
        let params = {
            userId: this.userProfile.id,
            ...passwords
        }
        await this.changePassword(params)
        .then(()=>{
            this.makeToast("Password", "password changed successfully !", "success");
            this.hideModalPassword();
        })
        .catch(err=>{
            this.makeToast("Password", err, "danger")
        })
    },

    openPassword() {
      this.showModalPassword();
    },

    showModalPassword() {
      this.$bvModal.show("password-modal");
    },
    hideModalPassword() {
      this.showModalAvatar();
    },

     openAvtr(){
      this.$bvModal.show("change-avatar-modal");
    },
    showModalAvatar() {
      this.$bvModal.show("change-avatar-modal");
    },
    hideModalAvatar() {
      this.$bvModal.hide("change-avatar-modal");
    },
    
    async updateAvatarControl(picture){
      let params = {
            userId: this.userProfile.id,
            avatar: picture,
        }
        await this.updateAvatar(params)
        .then(()=>{
            this.makeToast("Avatar", "avatar changed successfully !", "success");
            this.hideModalAvatar();
        })
        .catch(err=>{
            this.makeToast("Avatar", err, "danger")
        })
    },

    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },

    clear() {},

    cancel() {
      return;
    },
    cleanObject(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
  watch: {
    /* getAvatar(newVal){

    } */
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <ProfileData :user="userProfile" :avatar="getAvatar" @openPass="openPassword" @updateAvatar="openAvtr"/>
    <UpdateAvatar @picture="updateAvatarControl" :avatar="getAvatar"/>
    <ChangePassword @passwords="changePass" />
  </Layout>
</template>