var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center bg-white rounded-lg w-75 mt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"flex-container col-12 col-lg-6 col-xl-4 p-5 border-right"},[_c('img',{staticClass:"rounded-circle avatar",attrs:{"src":_vm.avatar ? _vm.avatar : _vm.stdAvatar,"alt":"Header Avatar"}}),_c('div',{staticClass:"mt-2"},[_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":_vm.updateAvatar}},[_vm._v(_vm._s(_vm.$t("buttons.updateAvatar.text")))])],1),_c('div',[_c('h5',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.userProfile.fullName))])]),_c('div',{staticClass:"role"},[_c('div',{staticClass:"badge font-size-12 text-center",class:{
            'badge-danger':
              `${_vm.userProfile.role}` === 'MANAGER' ||
              `${_vm.userProfile.role}` === 'SYSADMIN' ||
              `${_vm.userProfile.role}` === 'ADMIN',
            'badge-warning': `${_vm.userProfile.role}` === 'OPERATOR',
            'badge-primary': `${_vm.userProfile.role}` === 'USER',
          }},[_vm._v(" "+_vm._s(_vm.userProfile.role)+" ")])]),_c('div',{staticClass:"email"},[_c('i',{staticClass:"bx ri-mail-line"}),_vm._v(" "+_vm._s(_vm.userProfile.email)+" ")]),_c('div',{staticStyle:{"align-self":"flex-end"}},[_c('a',{staticClass:"password",on:{"click":_vm.openPassword}},[_vm._v(_vm._s(_vm.$t("buttons.changePassword.text")))])])]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-6 col-xl-8 p-0 flex-container-img"},[_c('img',{staticStyle:{"width":"75%","height":"auto"},attrs:{"src":require("@/assets/images/userBg.svg")}})])
}]

export { render, staticRenderFns }