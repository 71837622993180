<script>
/**
 * Profile component
 */
export default {
  props: ["user", "avatar"],
  components: {},
  data() {
    return {
      stdAvatar: require("@/assets/images/profile.png"),
      userProfile: {
        fullName: null,
        email: null,
        role: null,
      },
    };
  },
  computed: {},
  mounted() {},
  methods: {
    openPassword() {
      this.$emit("openPass");
    },
    updateAvatar() {
      this.$emit("updateAvatar");
    },
  },
  watch: {
    user(newVal) {
      this.userProfile = newVal;
    },
  },
};
</script>

<template>
  <div class="center bg-white rounded-lg w-75 mt-5">
    <div class="row">
      <div class="flex-container col-12 col-lg-6 col-xl-4 p-5 border-right">
        <img
          class="rounded-circle avatar"
          :src="avatar ? avatar : stdAvatar"
          alt="Header Avatar"
        />
        <div class="mt-2">
          <b-button size="sm" variant="success" @click="updateAvatar"
            >{{$t("buttons.updateAvatar.text")}}</b-button
          >
          
          <!-- <b-form-file
            id="fileF"
            v-model="avatarFile"
            placeholder="Update Avatar"
            drop-placeholder="Update Avatar"
          ></b-form-file> -->
          <!-- <input type="file" value="Update Avatar"/> -->
        </div>
        <div>
          <h5 class="mt-3">{{ userProfile.fullName }}</h5>
        </div>
        <div class="role">
          <div
            class="badge font-size-12 text-center"
            :class="{
              'badge-danger':
                `${userProfile.role}` === 'MANAGER' ||
                `${userProfile.role}` === 'SYSADMIN' ||
                `${userProfile.role}` === 'ADMIN',
              'badge-warning': `${userProfile.role}` === 'OPERATOR',
              'badge-primary': `${userProfile.role}` === 'USER',
            }"
          >
            {{ userProfile.role }}
          </div>
        </div>
        <div class="email">
          <i class="bx ri-mail-line" /> {{ userProfile.email }}
        </div>
        <div style="align-self: flex-end">
          <a class="password" @click="openPassword">{{
            $t("buttons.changePassword.text")
          }}</a>
        </div>
        <!-- <table class="mt-5">
            <tr>
              <th class="name">{{ userProfile.fullName }}</th>
            </tr>
            <tr>
              <td class="role">
                <div
                  class="badge font-size-12 text-center"
                  :class="{
                    'badge-danger':
                      `${userProfile.role}` === 'MANAGER' ||
                      `${userProfile.role}` === 'SYSADMIN' ||
                      `${userProfile.role}` === 'ADMIN',
                    'badge-warning': `${userProfile.role}` === 'OPERATOR',
                    'badge-primary': `${userProfile.role}` === 'USER',
                  }"
                >
                  {{ userProfile.role }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="email">
                <i class="bx ri-mail-line" /> {{ userProfile.email }}
              </td>
            </tr>
          </table> -->
      </div>
      <div class="col-12 col-lg-6 col-xl-8 p-0 flex-container-img">
        <img
          src="@/assets/images/userBg.svg"
          style="width: 75%; height: auto"
        />
        <!-- 
           -->
      </div>
    </div>
  </div>
</template>
<style>
.avatar {
  width: 60%;
  height: auto;
}
.email {
  height: 3em;
}
.role {
  height: 3em;
}
.bx {
  display: inline-block;
  min-width: 1.5rem;
  padding-bottom: 0.125em;
  font-size: 1.5rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #505d69;
  transition: all 0.4s;
}
.password:hover {
  color: rgb(251, 15, 15);
  cursor: pointer;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.flex-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
/* .flex-container .update {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  font-size: 6px;
  padding: 5px 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
} */
.flex-container-img {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 992px) {
  .flex-container-img {
    display: none;
  }
  .avatar {
    width: 35%;
    height: auto;
  }
}
</style>