<template>
  <div>
    <b-modal
      id="change-avatar-modal"
      ref="change-avatar-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.updateAvatar.text')"
    >
      <div class="row">
        <div class="col-12 col-lg-12 text-center">
          <img
            id="profilePicture"
            :src="pictureSrc ? pictureSrc : avatar ? avatar : stdAvatar"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-12">
          <b-form-file
            id="fileF"
            v-model="picture"
            :state="Boolean(picture)"
            :placeholder="$t('modals.choosePicture.text')"
            :drop-placeholder="$t('modals.dropPicture.text')"
            :browse-text="$t('modals.browse.text')"
            accept=".png"
          ></b-form-file>
        </div>
      </div>

      <template #modal-footer="{ cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="update()">
              {{ $t("buttons.valid.text") }}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()">
              {{ $t("buttons.cancel.text") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["avatar"],

  created() {},
  data() {
    return {
      stdAvatar: require("@/assets/images/profile.png"),
      picture: null,
      pictureSrc: null,
      err: {
        picture: null,
      },
    };
  },
  watch: {
    picture(newVal) {
      //document.getElementById("profilePicture").src = this.stdAvatar;
      this.base64Encode(newVal)
        .then((value) => {
          this.pictureSrc = value;
        })
        .catch(() => {
          this.pictureSrc = this.avatar;
        });
    },
  },
  methods: {
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    cancel() {
      this.clear();
    },
    clear() {
      (this.picture = null),
        (this.err = {
          picture: null,
        });
    },
    update() {
      if (!this.picture) this.err.picture = this.$t("msgError.obligField.text");
      else this.err.picture = "";

      if (!this.err.picture) {
        const formData = new FormData();
        formData.append("file", this.picture);
        this.$emit("picture", formData);
      }
    },
  },
};
</script>
<style>
#profilePicture {
  max-width: 300px;
}
</style>