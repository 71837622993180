<template>
  <div>
    <b-modal
      id="password-modal"
      ref="password-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.changePassword.text')"
    >
      <!-- old Password -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="oldPassword">{{ $t("modals.oldPassword.text") }}</label>
          <b-form-input
            id="oldPassword"
            v-model="passwords.oldPassword"
            type="password"
            class="mb-1"
            :placeholder="$t('modals.oldPassword.text')"
            :state="err.oldPassword ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.oldPassword }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- new Password -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="newPassword">{{ $t("modals.newPassword.text") }}</label>
          <b-form-input
            id="newPassword"
            type="password"
            v-model="passwords.newPassword"
            class="mb-1"
            :placeholder="$t('modals.newPassword.text')"
            :state="err.newPassword ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.newPassword }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- Re-Password -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="rePassword">{{ $t("modals.rePassword.text") }}</label>
          <b-form-input
            type="password"
            class="mb-1"
            id="rePassword"
            v-model="rePassword"
            :placeholder="$t('modals.rePassword.text')"
            :state="err.rePassword ? false : null"
          >
          </b-form-input>
          <b-form-invalid-feedback>
            {{ err.rePassword }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="valid()">
              {{ $t("buttons.valid.text") }}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()">
              {{ $t("buttons.cancel.text") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  created() {},
  data() {
    return {
      rePassword: null,
      passwords: {
        oldPassword: null,
        newPassword: null,
      },
      err: {
        oldPassword: null,
        newPassword: null,
        rePassword: null,
      },
    };
  },
  watch: {},
  methods: {
    cancel() {
      this.clear();
    },
    clear() {
      (this.rePassword = null),
        (this.passwords = {
          oldPassword: null,
          newPassword: null,
        });
      this.err = {
        oldPassword: null,
        newPassword: null,
        rePassword: null,
      };
    },
    valid() {
      let passwordValid = /^(?=.*?[A-Za-z])(?=.*?[0-9])[A-Za-z0-9].{7,128}$/;

      if (!this.passwords.oldPassword)
        this.err.oldPassword = this.$t("msgError.obligField.text");
      else this.err.oldPassword = "";

      if (!this.passwords.newPassword)
        this.err.newPassword = this.$t("msgError.obligField.text");
      else this.err.newPassword = "";

      if (!passwordValid.test(this.passwords.oldPassword))
        this.err.oldPassword = this.$t("msgError.passwordValidation.text");
      else this.err.oldPassword = "";

      if (!passwordValid.test(this.passwords.newPassword))
        this.err.newPassword = this.$t("msgError.passwordValidation.text");
      else this.err.newPassword = "";

      if (this.rePassword !== this.passwords.newPassword)
        this.err.rePassword = this.$t("msgError.rePasswordValidation.text");
      else this.err.rePassword = "";

      if (
        !this.err.oldPassword &&
        !this.err.newPassword &&
        !this.err.rePassword
      ) {
        this.$emit("passwords", this.passwords);
      }
    },
  },
};
</script>